.editor-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	overflow: hidden;
	margin-bottom: 5px;
	padding: 0 10px;

	.editor-content {
		height: 100%;
	}
}

.dark-theme .wrapper.project-page {
	background-color: #0a0a0a;
	color: whitesmoke;
}