.navbar {
    background-color: #1B1464;
    z-index: 50;

    .navbar-brand {
        font-size: 1.40rem;
        font-weight: 300;
        
        &:hover {
            color: whitesmoke;
        }
    }

    .navbar-brand, .nav-link {
        color: whitesmoke;
    }
}